import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFlags, usePrefs, useRequestData } from 'wsm-common-data';
import clickpathGenerator from '../utils/clickpathGenerator';
import { updateSession as trackingAttributesGenerator } from '../utils/trackingAttributesGenerator';
import trackingAttributesInjector from '../utils/trackingAttributesInjector';
import getParamsForExtraInfo from '../utils/getParamsForExtraInfo';
import pushEvent from '../utils/pushEvent';

// I think this is up here out of the widget component so it loads as fast as possible
// but I don't remember
if (typeof window !== 'undefined') {
	import('../utils/eventHandling/handleEvents.js').then((module) => {
		module.default(window);
	});
	window.DDC = window.DDC || {};
	window.DDC.WidgetData = window.DDC.WidgetData || {};
	window.DDC.WidgetData['ws-tracking'] =
		window.DDC.WidgetData['ws-tracking'] || {};
	// so that cms-web stuff can push through the pushEvent
	window.DDC.WidgetData['ws-tracking'].pushEvent = (event) =>
		pushEvent(window, {
			eventData: event.eventData,
			eventType: event.event
		});
	window.dispatchEvent(new Event('pushEventReady'));
}

const Widget = () => {
	const { dlItems } = useSelector((state) => state);

	const { formChangeTypes, maxClickpathCount, paramsForExtraInfo } =
		usePrefs();
	const { locale } = useRequestData();
	const flags = useFlags();

	if (typeof window !== 'undefined') {
		window.DDC.WidgetData = window.DDC.WidgetData || {};
		window.DDC.WidgetData['ws-tracking'] =
			window.DDC.WidgetData['ws-tracking'] || {};
		window.DDC.WidgetData['ws-tracking'].formChangeTypes =
			formChangeTypes?.split(' ');
		window.DDC.WidgetData['ws-tracking'].confirmationData = window.DDC
			.WidgetData['ws-tracking'].confirmationData || {
			form: { fields: {}, fieldsTrimmedLowerCase: {} }
		};
		window.DDC.WidgetData['ws-tracking'].flags = flags;
		// if there are dlItems then we are on a confirm page and want to grab values
		// out of the submitted form from the previous page and add them to the confirmation object
		if (dlItems && Object.entries(dlItems)?.length) {
			Object.entries(dlItems).forEach(([item, itemValue]) => {
				let valToUse = itemValue;
				if (itemValue === 'true') {
					valToUse = true;
				} else if (itemValue === 'false') {
					valToUse = false;
				}
				window.DDC.WidgetData[
					'ws-tracking'
				].confirmationData.form.fields[item.replace('dl.', '')] =
					valToUse;
			});
		}
		window.DDC.WidgetData['ws-tracking'].paramsForExtraInfo =
			paramsForExtraInfo;
		window.DDC.WidgetData['ws-tracking'].maxClickpathCount =
			maxClickpathCount;
	}

	useEffect(() => {
		clickpathGenerator();

		const urlParams = window.DDC.getUrlParams() || {};
		trackingAttributesGenerator(urlParams);
		trackingAttributesInjector({ locale });

		getParamsForExtraInfo(window, paramsForExtraInfo).forEach(
			(paramName) => {
				const EXTRA_INFO_PARAM = `extra-info-param-${paramName}`;
				const paramValue = window.DDC.getUrlParams()[paramName];
				if (paramValue) {
					window.sessionStorage.setItem(EXTRA_INFO_PARAM, paramValue);
				}
			}
		);
	});
	return <React.Fragment />;
};

export default Widget;
